import {
  PositionWithSpecialtiesProps,
  ProfessionalSpecialtiesProps,
} from './positionsAndSpecialties/interface';

type FormattedSpecialtiesProps = {
  positionId: number;
  allPositionsWithSpecialties?: PositionWithSpecialtiesProps[] | null | undefined;
  professionalSpecialties?: ProfessionalSpecialtiesProps;
};

export const formattedSpecialties = ({
  positionId,
  allPositionsWithSpecialties,
  professionalSpecialties,
}: FormattedSpecialtiesProps): string => {
  const allSpecialties =
    allPositionsWithSpecialties?.find(({ id }) => id === positionId)?.specialties ?? [];
  const professionalSpecialtyLabels = allSpecialties
    .filter(({ id }) => professionalSpecialties?.find(({ id: specialtyId }) => id === specialtyId))
    .map(({ label }) => label);
  return professionalSpecialtyLabels.join(' • ');
};

type GetSpecialtiesPreSelectedIdsProps = {
  positionWithSpecialtiesInfo?: PositionWithSpecialtiesProps | null;
  professionalSpecialties?: ProfessionalSpecialtiesProps;
};

export const getSpecialtiesPreSelectedIds = ({
  professionalSpecialties,
  positionWithSpecialtiesInfo,
}: GetSpecialtiesPreSelectedIdsProps) => {
  const professionalSpecialtiesIds = professionalSpecialties?.map((s) => s.id);
  const selectedPositionsFromSpecialties = positionWithSpecialtiesInfo?.specialties?.map(
    (s) => s.id,
  );
  const specialtiesPreSelected = professionalSpecialtiesIds?.filter((id) =>
    selectedPositionsFromSpecialties?.includes(id),
  );

  return specialtiesPreSelected;
};

type SelectedPositionsProps = {
  preSelectedSpecialties: number[] | undefined;
  selectedSpecialties: number[];
};

export const getNewPositions = ({
  preSelectedSpecialties,
  selectedSpecialties,
}: SelectedPositionsProps) => {
  const newSelectedSpecialties = !!selectedSpecialties
    ? selectedSpecialties.filter((id: number) => !preSelectedSpecialties?.includes(id))
    : [];

  return newSelectedSpecialties;
};

export const getRemovedPositions = ({
  preSelectedSpecialties,
  selectedSpecialties,
}: SelectedPositionsProps) => {
  const removedSpecialties = !!preSelectedSpecialties
    ? preSelectedSpecialties?.filter((id: number) => !selectedSpecialties?.includes(id))
    : [];

  return removedSpecialties;
};

export const hasChangesOnForm = ({
  preSelectedSpecialties,
  selectedSpecialties,
}: SelectedPositionsProps) => {
  const addedPositions = getNewPositions({
    preSelectedSpecialties,
    selectedSpecialties,
  });
  const removedPositions = getRemovedPositions({
    preSelectedSpecialties,
    selectedSpecialties,
  });

  const hasAddedPositions = !!addedPositions.length;
  const hasRemovedPositions = !!removedPositions.length;

  const hasNoPayload = !hasAddedPositions && !hasRemovedPositions;

  return {
    hasNoPayload,
    hasAddedPositions,
    hasRemovedPositions,
    removedPositions,
    addedPositions,
  };
};

export const createSpecialtiesPayload = ({
  preSelectedSpecialties,
  selectedSpecialties,
}: SelectedPositionsProps) => {
  const hasChangesOnFormPayload = hasChangesOnForm({ preSelectedSpecialties, selectedSpecialties });

  const addPayloadData = hasChangesOnFormPayload.hasAddedPositions
    ? { add_specialty_ids: hasChangesOnFormPayload.addedPositions }
    : null;
  const removePayloadData = hasChangesOnFormPayload.hasRemovedPositions
    ? { remove_specialty_ids: hasChangesOnFormPayload.removedPositions }
    : null;

  const payload = { ...addPayloadData, ...removePayloadData };

  const hasNoPayloadChange = hasChangesOnFormPayload.hasNoPayload;

  return { payload, hasNoPayloadChange };
};
