import { Box, Label, Text } from '@medely/ui-kit';
import { ContentLoader } from '../../../components';
import React from 'react';
import {
  CurrentUserForPositionsAndSpecialtiesViewGql,
  PositionsWithSpecialtiesForPositionsAndSpecialtiesViewGql,
} from './PositionsAndSpecialtiesQueries';
import { ResultOf } from '@graphql-typed-document-node/core';
import { formattedSpecialties } from '../utils';
import { NoPositionsAndSpecialties } from './components/NoPositionsAndSpecialties';
import { useGraphQLQuery } from '@mp/hooks/useGraphQLRequest';

interface IFormattedPosition {
  header: string;
  description: string;
  id: number;
}
export const PositionsAndSpecialtiesViewLegacy = () => {
  const { data, isLoading: loadingUser } = useGraphQLQuery<
    ResultOf<typeof CurrentUserForPositionsAndSpecialtiesViewGql>
  >({
    operationName: 'GetCurrentUserForPositionsAndSpecialtiesView',
    query: CurrentUserForPositionsAndSpecialtiesViewGql,
    variables: {},
  });

  const currentUser = data?.me;
  const professionalPositions = currentUser?.professional?.positions ?? [];
  const professionalPositionIds = professionalPositions.map(({ id }) => id);
  const professionalSpecialties = currentUser?.professional?.specialties;

  const { data: positionsWithSpecialties, isLoading: loadingPositions } = useGraphQLQuery<
    ResultOf<typeof PositionsWithSpecialtiesForPositionsAndSpecialtiesViewGql>
  >({
    operationName: 'GetPositionsWithSpecialtiesForPositionsAndSpecialtiesView',
    query: PositionsWithSpecialtiesForPositionsAndSpecialtiesViewGql,
    variables: {
      input: {
        search: {
          position_ids: professionalPositionIds,
        },
      },
    },
  });
  const allPositionsWithSpecialties = positionsWithSpecialties?.positions;

  const formattedPositions: IFormattedPosition[] = professionalPositions.map((position) => ({
    header: position.display_name ?? '',
    description: formattedSpecialties({
      positionId: position.id,
      allPositionsWithSpecialties,
      professionalSpecialties,
    }),
    id: position.id,
  }));

  return (
    <ContentLoader loading={loadingUser || loadingPositions}>
      {!!formattedPositions.length ? (
        <Box>
          {formattedPositions.map(({ header, description, id }) => (
            <Box key={`position-${id}`} py={1}>
              <Label size="m">{header}</Label>
              <Box py={!!description ? 0.5 : 0}>
                <Text size="m">{description}</Text>
              </Box>
            </Box>
          ))}
        </Box>
      ) : (
        <NoPositionsAndSpecialties />
      )}
    </ContentLoader>
  );
};
