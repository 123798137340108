import React, { useEffect, useRef } from 'react';
import { Box, Heading } from '@medely/ui-kit';
import { ContentLoader } from '../../../components';
import { NoPositionsAndSpecialties } from './components/NoPositionsAndSpecialties';
import { PositionProps } from './interface';
import { PositionAndSpecialtyItem } from './components/PositionAndSpecialtyItem';
import { formattedSpecialties } from '../utils';
import { SpecialtiesView } from './SpecialtiesView';
import { ResultOf } from '@graphql-typed-document-node/core';
import {
  CurrentUserForPositionsAndSpecialtiesViewGql,
  PositionsWithSpecialtiesForPositionsAndSpecialtiesViewGql,
} from './PositionsAndSpecialtiesQueries';
import { useGraphQLQuery } from '@mp/hooks/useGraphQLRequest';
import useSpecialtiesMutations from '@mp/hooks/useSpecialtiesMutations';
import { HasNoSpecialtyInfo } from './components/HasNoSpecialtyView';

type PositionsAndSpecialtiesViewProps = {
  showTitle?: boolean;
  onPageChange?: (params: boolean) => void;
};

export const PositionsAndSpecialtiesView = ({
  showTitle = false,
  onPageChange,
}: PositionsAndSpecialtiesViewProps) => {
  const [selectedPosition, setSelectedPosition] = React.useState<undefined | number>();

  const { data, isLoading: loadingUser } = useGraphQLQuery<
    ResultOf<typeof CurrentUserForPositionsAndSpecialtiesViewGql>
  >({
    operationName: 'GetCurrentUserForPositionsAndSpecialtiesView',
    query: CurrentUserForPositionsAndSpecialtiesViewGql,
    variables: {},
  });
  const currentUser = data?.me;

  const hasPosted = useRef(false);
  const { trackViewedSpecialties } = useSpecialtiesMutations();
  const professionalPositions = currentUser?.professional?.positions ?? [];
  const professionalPositionIds = professionalPositions.map(({ id }) => id);
  const professionalSpecialties = currentUser?.professional?.specialties;

  const { data: positionsWithSpecialties, isLoading: loadingPositions } = useGraphQLQuery<
    ResultOf<typeof PositionsWithSpecialtiesForPositionsAndSpecialtiesViewGql>
  >({
    operationName: 'GetPositionsWithSpecialtiesForPositionsAndSpecialtiesView',
    query: PositionsWithSpecialtiesForPositionsAndSpecialtiesViewGql,
    variables: {
      input: {
        search: {
          position_ids: professionalPositionIds,
        },
      },
    },
  });
  const allPositionsWithSpecialties = positionsWithSpecialties?.positions;

  const formattedPositions: PositionProps[] = professionalPositions.map((position) => ({
    header: position.display_name ?? '',
    description: formattedSpecialties({
      positionId: position.id,
      professionalSpecialties,
      allPositionsWithSpecialties,
    }),
    id: position.id,
  }));

  useEffect(() => {
    if (hasPosted.current) {
      return;
    }
    hasPosted.current = true;
    trackViewedSpecialties();
  }, [trackViewedSpecialties]);

  const handleUpdatePositionClick = (positionId: number) => {
    setSelectedPosition(positionId);
  };

  // We call this every render to ensuer the header is shown/hidden accurately
  // Normally we'd place this logic in the handlers themselves, however the handler
  // is prop drilled far enough that it adds unexpected complexity, in which this
  // solution works better then the handlers.
  // TODO(PRO-3729): Look at refactor opportunities
  onPageChange && onPageChange(true);
  if (!!selectedPosition) {
    onPageChange && onPageChange(false);
    const positionWithSpecialtiesInfo = allPositionsWithSpecialties?.find(
      (position) => position.id === selectedPosition,
    );
    const hasSpecialties = !!positionWithSpecialtiesInfo?.specialties;

    return (
      <ContentLoader loading={loadingUser || loadingPositions}>
        {hasSpecialties ? (
          <SpecialtiesView
            positionWithSpecialtiesInfo={positionWithSpecialtiesInfo}
            professionalSpecialties={professionalSpecialties}
            onBackClick={setSelectedPosition}
          />
        ) : (
          <HasNoSpecialtyInfo
            handleBackClick={setSelectedPosition}
            positionName={positionWithSpecialtiesInfo?.display_name || ''}
          />
        )}
      </ContentLoader>
    );
  }

  return (
    <ContentLoader loading={loadingUser || loadingPositions}>
      <>
        {showTitle && (
          <Heading size="m" color="text.primary">
            Positions and specialties
          </Heading>
        )}
        {!!formattedPositions.length ? (
          <Box>
            {formattedPositions.map(({ header, description, id }) => (
              <Box key={`position-${id}`} onClick={() => handleUpdatePositionClick(id)}>
                <PositionAndSpecialtyItem header={header} description={description} id={id} />
              </Box>
            ))}
          </Box>
        ) : (
          <NoPositionsAndSpecialties />
        )}
      </>
    </ContentLoader>
  );
};
